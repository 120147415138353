<template>
  <v-app>
    <v-snackbar
      v-model="message.view"
      class="headerSnackbar"
      :color="message.color"
      :timeout="message.timeout"
      centered
      top
    >
      {{ message.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="message.view = false"
          id="closetab"
        >
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-app-bar clipped-left light app color="blue-grey lighten-5">
      <v-toolbar-title>
        <img src="../../assets/logo.svg" width="200" />
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container grid-list-md v-if="isTrainingPeriod != null">
        <h2 class="pt-4 pb-2 text-center">実務修習受講申請書作成</h2>
        <v-row
          class="my-5"
          justify="center"
          align="center"
          v-if="!isTrainingPeriod"
          ><v-col lg="8">
            <v-alert outlined type="warning" prominent border="left">
              現在は申請期間ではありません。
            </v-alert></v-col
          >
        </v-row>
        <v-row class="my-5" justify="center" align="center" v-else>
          <v-col lg="8">
            <p class="caption">
              入力にあたっては、必ず「実務修習のお知らせ」（日本弁理士会HP実務修習ページに掲載）をご確認ください。
            </p>
            <div class="text-center py-5">
              <v-dialog v-model="restore_temp_dialog" width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    dark
                    color="orange darken-3 font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    下書きを復元する
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text class="pt-3">
                    <div class="d-flex justify-start align-center my-5">
                      <span style="width: 100px" class="mr-2 font-weight-bold"
                        >ID</span
                      >
                      <div style="width: 220px">
                        <v-text-field
                          single-line
                          outlined
                          hide-details
                          dense
                          background-color="white"
                          color="cyan darken-2"
                          class="mr-2"
                          v-model="temp_id"
                          autocomplete="nope"
                        ></v-text-field>
                        <div
                          v-if="errors && errors.temp_id"
                          class="text-danger text-error"
                        >
                          {{ errors.temp_id[0] }}
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-start align-center">
                      <span style="width: 100px" class="mr-2 font-weight-bold"
                        >パスワード</span
                      >
                      <div style="width: 220px">
                        <v-text-field
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                          :type="showPassword ? 'text' : 'password'"
                          single-line
                          outlined
                          hide-details
                          dense
                          background-color="white"
                          color="cyan darken-2"
                          class="mr-2"
                          v-model="password"
                          autocomplete="nope"
                        ></v-text-field>
                        <div
                          v-if="errors && errors.password"
                          class="text-danger text-error"
                        >
                          {{ errors.password[0] }}
                        </div>
                      </div>
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions class="d-flex justify-center py-5">
                    <v-btn
                      color="cyan darken-3"
                      class="font-weight-bold white--text"
                      :disabled="restoreButtonStatus"
                      :loading="restoreButtonStatus"
                      @click="restoreTempData"
                    >
                      下書きを復元する
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-card class="mb-9">
              <v-card-title class="px-4 py-2 cyan darken-1">
                <h3 class="subtitle-1 font-weight-bold white--text">
                  申請者に関する基本情報
                </h3>
              </v-card-title>
              <v-simple-table class="transparent my-5">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>
                        フリガナ<span class="ml-1 red--text text--darken-2"
                          >※</span
                        ><br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >全角カタカナ</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <div class="mr-2">セイ</div>
                          <div style="width: 200px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="ヤマダ"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.familyname_kana"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.familyname_kana"
                              class="text-danger text-error"
                            >
                              {{ errors.familyname_kana[0] }}
                            </div>
                          </div>
                          <div class="ml-2 mr-1">メイ</div>
                          <div style="width: 200px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="タロウ"
                              background-color="white"
                              color="cyan darken-2"
                              class="ml-2"
                              v-model="fields.firstname_kana"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.firstname_kana"
                              class="text-danger text-error"
                            >
                              {{ errors.firstname_kana[0] }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        氏名<span class="ml-1 red--text text--darken-2">※</span
                        ><br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >全角</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <div class="mr-5">姓</div>
                          <div style="width: 200px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="山田"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.familyname_kanji"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.familyname_kanji"
                              class="text-danger text-error"
                            >
                              {{ errors.familyname_kanji[0] }}
                            </div>
                          </div>
                          <div class="ml-2 mr-4">名</div>
                          <div style="width: 200px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="太郎"
                              background-color="white"
                              color="cyan darken-2"
                              class="ml-2"
                              v-model="fields.firstname_kanji"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.firstname_kanji"
                              class="text-danger text-error"
                            >
                              {{ errors.firstname_kanji[0] }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="100" class="py-2">
                        生年月日<span class="ml-1 red--text text--darken-2"
                          >※</span
                        >
                        <br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >半角数字</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <div style="width: 150px">
                            <v-select
                              :items="[
                                { label: '和暦を選択', id: '' },
                                ...birth_year_era,
                              ]"
                              item-text="label"
                              item-value="id"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              flat
                              dense
                              v-model="fields.birth_era"
                            ></v-select>
                          </div>
                          <div style="width: 100px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              background-color="white"
                              color="cyan darken-2"
                              class="ml-2"
                              v-model="fields.birth_year"
                              autocomplete="nope"
                            ></v-text-field>
                          </div>
                          <div class="mr-5">年</div>
                          <div
                            v-if="errors && errors.birth_year"
                            class="text-danger text-error"
                          >
                            {{ errors.birth_year[0] }}
                          </div>
                          <div style="width: 100px">
                            <v-select
                              :items="[...months]"
                              item-text="label"
                              item-value="id"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              flat
                              dense
                              v-model="fields.birth_month"
                            ></v-select>
                          </div>
                          <div class="mr-5">月</div>
                          <div
                            v-if="errors && errors.birth_month"
                            class="text-danger text-error"
                          >
                            {{ errors.birth_month[0] }}
                          </div>
                          <div style="width: 100px">
                            <v-select
                              :items="[...days]"
                              item-text="label"
                              item-value="id"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              flat
                              dense
                              v-model="fields.birth_day"
                            ></v-select>
                          </div>
                          <div class="mr-5">日</div>
                          <div
                            v-if="errors && errors.birth_day"
                            class="text-danger text-error"
                          >
                            {{ errors.birth_day[0] }}
                          </div>
                          <div
                            v-if="errors && errors.date_error"
                            class="text-danger text-error"
                          >
                            {{ errors.date_error[0] }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        性別<span class="ml-1 red--text text--darken-2">※</span>
                      </th>
                      <td>
                        <v-radio-group v-model="fields.sex_cd" row
                          ><v-radio
                            v-for="type in sex_cds"
                            :key="type.id"
                            :label="type.label"
                            :value="type.id"
                            color="indigo"
                            class="mr-5"
                          ></v-radio>
                        </v-radio-group>
                        <div v-if="errors && errors.sex_cd" class="text-danger text-error">
                          {{ errors.sex_cd[0] }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="150">
                        住所<span class="ml-1 red--text text--darken-2">※</span>
                      </th>
                      <td class="pb-2">
                        <div class="d-flex justify-start align-center pb-2">
                          <div style="width: 200px">
                            <v-text-field
                              label="郵便番号(ハイフン無し)"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              dense
                              v-model="fields.adress_postalcode"
                              autocomplete="nope"
                              :maxlength="7"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.adress_postalcode"
                              class="text-danger text-error"
                            >
                              {{ errors.adress_postalcode[0] }}
                            </div>
                          </div>

                          <v-btn class="mx-2" @click="getPrefecture"
                            >住所検索
                          </v-btn>
                          <v-btn @click="setKaiGai(true)">海外</v-btn>

                          <div class="ml-md-3">
                            <v-tooltip bottom :open-on-hover="false">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  small
                                  @click="on.click"
                                  @blur="on.blur"
                                  retain-focus-on-click
                                  text
                                  color="cyan darken-2"
                                >
                                  <v-icon dark depressed color="black">
                                    mdi-chat-question-outline
                                  </v-icon>
                                  <span
                                    class="text-caption ml-1 text-decoration-underline"
                                    >海外にお住まいの方</span
                                  >
                                </v-btn>
                              </template>
                              <p class="mb-0">
                                実務修習の発送物は日本国内にしか発送できません。<br>住所で海外を選択された方は、下段送付先は「勤務先」を選択し、<br>発送物を受領できる日本の住所及び郵便番号を入力してください。
                              </p>
                            </v-tooltip>
                          </div>
                        </div>
                        <div class="d-flex justify-start align-center pb-2">
                          <div style="width: 200px">
                            <v-select
                              label="都道府県"
                              :items="[
                                { label: '選択してください', id: '' },
                                ...office_prefecture,
                              ]"
                              item-text="label"
                              item-value="id"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              flat
                              dense
                              v-model="fields.adress_prefecture"
                            ></v-select>
                            <div
                              v-if="errors && errors.adress_prefecture"
                              class="text-danger text-error"
                            >
                              {{ errors.adress_prefecture[0] }}
                            </div>
                          </div>
                          <div
                            class="grey--text text--darken-1 ml-5 text-caption"
                          >
                            郵便物が必ず届くように正確に入力してください。
                          </div>
                        </div>
                        <v-text-field
                          label="住所"
                          outlined
                          hide-details
                          color="cyan darken-2"
                          class="mb-2"
                          background-color="white"
                          v-model="fields.adress_municipality"
                          dense
                          autocomplete="nope"
                        ></v-text-field>
                        <div
                          v-if="errors && errors.adress_municipality"
                          class="text-danger text-error"
                        >
                          {{ errors.adress_municipality[0] }}
                        </div>
                        <v-text-field
                          label="建物名以下"
                          outlined
                          hide-details
                          color="cyan darken-2"
                          background-color="white"
                          dense
                          v-model="fields.adress_building"
                          autocomplete="nope"
                        ></v-text-field>
                        <div
                          v-if="errors && errors.adress_building"
                          class="text-danger text-error"
                        >
                          {{ errors.adress_building[0] }}
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          アパート・マンション名・部屋番号、同居の場合は～方等についても必ず入力してください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="100">
                        電話番号<span class="ml-1 red--text text--darken-2"
                          >※</span
                        >
                        <br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >半角数字<br>ハイフンあり</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <div class="ml-2 mr-4">TEL 1</div>
                          <div style="width: 200px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="03-3519-2360"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.tel1"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.tel1"
                              class="text-danger text-error"
                            >
                              {{ errors.tel1[0] }}
                            </div>
                          </div>
                          <div class="ml-2 mr-4">TEL 2</div>
                          <div style="width: 200px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="03-3519-2360"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.tel2"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.tel2"
                              class="text-danger text-error"
                            >
                              {{ errors.tel2[0] }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          電話番号は、２つまで登録することができます。
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>FAX番号
                        <br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >半角数字<br>ハイフンあり</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-space-between">
                          <div style="width: 200px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="03-3519-2360"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.fax"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.fax"
                              class="text-danger text-error"
                            >
                              {{ errors.fax[0] }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          FAX番号がない場合は、空欄で結構です。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        メールアドレス1<span
                          class="ml-1 red--text text--darken-2"
                          >※</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <div style="width: 340px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="yamada@jpaa.or.jp"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.email1"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.email1"
                              class="text-danger text-error"
                            >
                              {{ errors.email1[0] }}
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-start align-center mt-3">
                          <div style="width: 340px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="yamada@jpaa.or.jp"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.email1_confirm"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.email1_confirm"
                              class="text-danger text-error"
                            >
                              {{ errors.email1_confirm[0] }}
                            </div>
                          </div>
                          <div class="ml-2 mr-4">
                            確認のため同じアドレスを入力
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メールアドレス2</th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <div style="width: 340px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="yamada_tarou@jpaa.or.jp"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.email2"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.email2"
                              class="text-danger text-error"
                            >
                              {{ errors.email2[0] }}
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-start align-center mt-3">
                          <div style="width: 340px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              placeholder="yamada_tarou@jpaa.or.jp"
                              background-color="white"
                              color="cyan darken-2"
                              class="mr-2"
                              v-model="fields.email2_confirm"
                              autocomplete="nope"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.email2_confirm"
                              class="text-danger text-error"
                            >
                              {{ errors.email2_confirm[0] }}
                            </div>
                          </div>
                          <div class="ml-2 mr-4">
                            確認のため同じアドレスを入力
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <v-card class="mb-9">
              <v-card-title class="px-4 py-2 cyan darken-1">
                <h3 class="subtitle-1 font-weight-bold white--text">
                  実務修習の受講に関する事項
                </h3>
              </v-card-title>
              <v-simple-table class="transparent my-5">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th width="20%">
                        受講希望コース<span
                          class="ml-1 red--text text--darken-2"
                          >※</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center pb-2">
                          <span class="mr-3" style="width: 80px">第一希望</span>
                          <v-select
                            :items="[
                              { label: '選択してください', id: '' },
                              ...courses,
                            ]"
                            item-text="label"
                            item-value="id"
                            outlined
                            hide-details
                            color="green darken-3"
                            background-color="white"
                            flat
                            dense
                            v-model="fields.desired_course1"
                          ></v-select>
                        </div>
                        <div
                          v-if="errors && errors.desired_course1"
                          class="d-flex text-danger text-error"
                        >
                          <span class="mr-3" style="width: 80px"></span>
                          {{ errors.desired_course1[0] }}
                        </div>
                        <div class="d-flex justify-start align-center pb-2">
                          <span class="mr-3" style="width: 80px">第二希望</span>
                          <v-select
                            :items="[
                              { label: '選択してください', id: '' },
                              ...courses,
                            ]"
                            item-text="label"
                            item-value="id"
                            outlined
                            hide-details
                            color="green darken-3"
                            background-color="white"
                            flat
                            dense
                            v-model="fields.desired_course2"
                          ></v-select>
                        </div>
                        <div
                          v-if="errors && errors.desired_course2"
                          class="d-flex text-danger text-error"
                        >
                          <span class="mr-3" style="width: 80px"></span>
                          {{ errors.desired_course2[0] }}
                        </div>
                        <div class="d-flex justify-start align-center">
                          <span class="mr-3" style="width: 80px">第三希望</span>
                          <v-select
                            :items="[
                              { label: '選択してください', id: '' },
                              ...courses,
                            ]"
                            item-text="label"
                            item-value="id"
                            outlined
                            hide-details
                            color="green darken-3"
                            background-color="white"
                            flat
                            dense
                            v-model="fields.desired_course3"
                          ></v-select>
                        </div>
                        <div
                          v-if="errors && errors.desired_course3"
                          class="d-flex text-danger text-error"
                        >
                          <span class="mr-3" style="width: 80px"></span>
                          {{ errors.desired_course3[0] }}
                        </div>
                        <div
                          class="red--text text--darken-2 mt-3 text-caption font-weight-bold"
                        >
                          必ず第３希望まで選択してください。免除を申請する方も必ず選択してください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        受講資格<span class="ml-1 red--text text--darken-2"
                          >※</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <v-select
                            :items="qualifications"
                            item-text="label"
                            item-value="id"
                            outlined
                            hide-details
                            color="green darken-3"
                            background-color="white"
                            flat
                            dense
                            v-model="fields.capacity"
                            autocomplete="nope"
                          ></v-select>

                          <span
                            class="ml-3 grey--text text--darken-1 text-caption"
                            >該当する受講資格を選択してください。</span
                          >
                        </div>
                        <div
                          v-if="errors && errors.capacity"
                          class="text-danger text-error"
                        >
                          {{ errors.capacity[0] }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        受講資格取得年
                        <br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >半角数字</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-start align-center">
                          <div style="width: 150px">
                            <v-select
                              :items="[
                                { label: '和暦を選択', id: '' },
                                ...eligibility_year_era,
                              ]"
                              item-text="label"
                              item-value="id"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              flat
                              dense
                              v-model="fields.eligibility_era"
                            ></v-select>
                          </div>
                          <div style="width: 80px">
                            <v-text-field
                              single-line
                              outlined
                              hide-details
                              dense
                              background-color="white"
                              color="cyan darken-2"
                              class="ml-2"
                              v-model="fields.eligibility_year"
                              autocomplete="nope"
                            ></v-text-field>
                          </div>
                          <div class="mr-5">年</div>
                          <div
                            v-if="errors && errors.eligibility_year"
                            class="text-danger text-error"
                          >
                            {{ errors.eligibility_year[0] }}
                          </div>
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          ・弁理士試験合格者は、<strong>合格年</strong>を入力してください。<br />
                          ・弁護士有資格者は、<strong>司法修習終了年</strong>を入力してください。<br />
                          ・特許庁有資格者は、特許庁において審判官又は審査官として審判又は審査の事務に従事した期間が<br /><strong
                            >通算して７年以上になった年</strong
                          >を入力してください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        受講資格記載事項<span
                          class="ml-1 red--text text--darken-2"
                          >※</span
                        ><br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >半角数字</span
                        >
                      </th>
                      <td>
                        <div style="width: 300px">
                          <v-text-field
                            single-line
                            outlined
                            hide-details
                            dense
                            placeholder="99999"
                            background-color="white"
                            color="cyan darken-2"
                            class="mr-2"
                            v-model="fields.eligibility_requirement"
                            autocomplete="nope"
                          ></v-text-field>
                          <div
                            v-if="errors && errors.eligibility_requirement"
                            class="text-danger text-error"
                          >
                            {{ errors.eligibility_requirement[0] }}
                          </div>
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          ・弁理士試験合格者は、<strong>合格証書番号</strong>を入力してください。<br />
                          ・弁護士有資格者は、<strong>登録番号又は司法修習修了証書番号</strong>を入力してください。<br />
                          ・特許庁有資格者は、<strong>審判官又は審査官としての従事年数（通算）</strong>を入力してください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        送付先<span class="ml-1 red--text text--darken-2"
                          >※</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-content-start align-center">
                          <div style="width: 50%">
                            <v-radio-group row v-model="fields.destination"
                            @change="changeDestination">
                              <v-radio
                                label="住所"
                                color="cyan darken-2"
                                hide-details
                                dense
                                :value="0"
                              ></v-radio>
                              <v-radio
                                label="勤務先"
                                color="cyan darken-2"
                                hide-details
                                dense
                                :value="1"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                          <div
                            class="grey--text text--darken-1 ml-3 text-caption"
                          >
                            テキスト等の発送先となります。<br />勤務先を選択した場合は、「勤務先に関する事項」を必ずご記入ください。
                          </div>
                        </div>
                        <div
                          v-if="errors && errors.destination"
                          class="text-danger text-error"
                        >
                          {{ errors.destination[0] }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        免除申請の有無<span
                          class="ml-1 red--text text--darken-2"
                          >※</span
                        >
                      </th>
                      <td>
                        <div class="d-flex justify-content-start align-center">
                          <div style="width: 50%">
                            <v-radio-group
                              row
                              v-model="fields.exemption"
                              @change="changeExemption"
                            >
                              <v-radio
                                label="申請する"
                                :value="1"
                                color="cyan darken-2"
                                hide-details
                              ></v-radio>
                              <v-radio
                                label="申請しない"
                                :value="0"
                                color="cyan darken-2"
                                hide-details
                              ></v-radio>
                            </v-radio-group>
                          </div>
                          <div
                            class="red--text text--darken-2 ml-3 text-caption font-weight-bold"
                          >
                            免除を申請する方は、「申請する」を選択の上、<br />以下の「免除申請に関する事項」についても必ず入力してください。
                          </div>
                        </div>
                        <div
                          v-if="errors && errors.exemption"
                          class="text-danger text-error"
                        >
                          {{ errors.exemption[0] }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            <v-card class="mb-9">
              <v-card-title
                :class="[
                  { 'cyan darken-1': fields.destination === 1 },
                  {
                    'grey lighten-1':
                      fields.destination === 0 || fields.destination === null,
                  },
                  'px-4 py-2',
                ]"
              >
                <h3 class="subtitle-1 font-weight-bold white--text">
                  勤務先に関する事項
                </h3>
              </v-card-title>

              <v-simple-table
                :class="[
                  {
                    disabled:
                      fields.destination === 0 || fields.destination === null,
                  },
                  'transparent my-5',
                ]"
              >
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>
                        勤務先名（会社名、事務所名等）<span
                          class="ml-1 red--text text--darken-2"
                          v-if="fields.destination == 1"
                          >※</span
                        >
                      </th>
                      <td>
                        <v-text-field
                          single-line
                          outlined
                          hide-details
                          dense
                          placeholder="ABC株式会社"
                          background-color="white"
                          color="cyan darken-2"
                          class="mr-2"
                          v-model="fields.office_name1"
                          :disabled="
                              fields.destination === 0 ||
                              fields.destination === null
                            "
                          autocomplete="nope"
                        ></v-text-field>
                        <div
                          v-if="errors && errors.office_name1"
                          class="text-danger text-error"
                        >
                          {{ errors.office_name1[0] }}
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          省略せず、正式名称を記載してください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        部署名<span
                          class="ml-1 red--text text--darken-2"
                          v-if="fields.destination == 1"
                          >※</span
                        >
                      </th>
                      <td>
                        <v-text-field
                          single-line
                          outlined
                          hide-details
                          dense
                          placeholder="知的財産部"
                          background-color="white"
                          color="cyan darken-2"
                          class="mr-2"
                          v-model="fields.office_name2"
                          :disabled="
                              fields.destination === 0 ||
                              fields.destination === null
                            "
                          autocomplete="nope"
                        ></v-text-field>
                        <div
                          v-if="errors && errors.office_name2"
                          class="text-danger text-error"
                        >
                          {{ errors.office_name2[0] }}
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          所属先の部署名等まで詳細に入力してください。部署がない場合は「部署なし」と入力してください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="150">
                        所在地<span
                          class="ml-1 red--text text--darken-2"
                          v-if="fields.destination == 1"
                          >※</span
                        >
                      </th>
                      <td class="pb-2">
                        <div class="d-flex justify-start align-center pb-2">
                          <div style="width: 200px">
                            <v-text-field
                              label="郵便番号(ハイフン無し)"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              dense
                              v-model="fields.office_postalcode"
                              :disabled="
                              fields.destination === 0 ||
                              fields.destination === null
                            "
                              autocomplete="nope"
                              :maxlength="7"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.office_postalcode"
                              class="text-danger text-error"
                            >
                              {{ errors.office_postalcode[0] }}
                            </div>
                          </div>

                          <v-btn class="mx-2" @click="getOfficePrefecture"
                            >住所検索
                          </v-btn>
                          <v-btn @click="setKaiGai()">海外</v-btn>
                        </div>
                        <div class="d-flex justify-start align-center pb-2">
                          <div style="width: 200px">
                            <v-select
                              label="都道府県"
                              :items="[
                                { label: '選択してください', id: '' },
                                ...office_prefecture,
                              ]"
                              item-text="label"
                              item-value="id"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              flat
                              dense
                              :disabled="
                              fields.destination === 0 ||
                              fields.destination === null
                            "
                              v-model="fields.office_prefecture"
                            ></v-select>
                            <div
                              v-if="errors && errors.office_prefecture"
                              class="text-danger text-error"
                            >
                              {{ errors.office_prefecture[0] }}
                            </div>
                          </div>
                          <div
                            class="grey--text text--darken-1 ml-5 text-caption"
                          >
                            郵便物が必ず届くように正確に入力してください。
                          </div>
                        </div>
                        <v-text-field
                          label="住所"
                          outlined
                          hide-details
                          color="cyan darken-2"
                          class="mb-2"
                          background-color="white"
                          :disabled="
                              fields.destination === 0 ||
                              fields.destination === null
                            "
                          v-model="fields.office_municipality"
                          autocomplete="nope"
                          dense
                        ></v-text-field>
                        <div
                          v-if="errors && errors.office_municipality"
                          class="text-danger text-error"
                        >
                          {{ errors.office_municipality[0] }}
                        </div>
                        <v-text-field
                          label="建物名以下"
                          outlined
                          hide-details
                          color="cyan darken-2"
                          background-color="white"
                          :disabled="
                              fields.destination === 0 ||
                              fields.destination === null
                            "
                          v-model="fields.office_building"
                          autocomplete="nope"
                          dense
                        ></v-text-field>
                        <div
                          v-if="errors && errors.office_building"
                          class="text-danger text-error"
                        >
                          {{ errors.office_building[0] }}
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          ビル名、部屋番号等も必ず入力してください。
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            <v-card class="mb-9">
              <v-card-title
                :class="[
                  { 'cyan darken-1': fields.exemption === 1 },
                  {
                    'grey lighten-1':
                      fields.exemption === 0 || fields.exemption === null,
                  },
                  'px-4 py-2',
                ]"
              >
                <h3 class="subtitle-1 font-weight-bold white--text">
                  免除申請に関する事項
                </h3>
              </v-card-title>
              <v-simple-table
                :class="[
                  {
                    disabled:
                      fields.exemption === 0 || fields.exemption === null,
                  },
                  'transparent my-5',
                ]"
              >
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th width="23%">免除対象課程（号）<span
                          class="ml-1 red--text text--darken-2"
                          v-if="fields.exemption == 1"
                          >※</span></th>
                      <td>
                        <div style="width: 100px">
                          <v-select
                            :items="[{ id: '', label: '' }, ...exempt_courses]"
                            item-text="label"
                            item-value="id"
                            outlined
                            hide-details
                            color="cyan darken-3"
                            background-color="white"
                            flat
                            dense
                            :disabled="
                              fields.exemption === 0 ||
                              fields.exemption === null
                            "
                            v-model="fields.exempt_course"
                          ></v-select>
                        </div>
                        <div
                          v-if="errors && errors.exempt_course"
                          class="text-danger text-error"
                        >
                          {{ errors.exempt_course[0] }}
                        </div>
                        <div
                          class="red--text text--darken-2 mt-3 text-caption font-weight-bold"
                        >
                          免除の対象となる課程として、弁理士法施行規則第２１条の２第１項の該当する号番号を選択してください。
                        </div>
                        <div class="text-caption">
                          2：特許・実用新案<br />3：意匠<br />4：商標<br />5：条約その他
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="20%">免除申請理由（号）<span
                          class="ml-1 red--text text--darken-2"
                          v-if="fields.exemption == 1"
                          >※</span></th>
                      <td>
                        <div style="width: 100px">
                          <v-select
                            :items="[{ id: '' }, ...exempt_reason_ids]"
                            item-text="id"
                            item-value="id"
                            outlined
                            hide-details
                            color="cyan darken-3"
                            background-color="white"
                            flat
                            dense
                            v-model="fields.exempt_reason_id"
                            :disabled="
                              fields.exemption === 0 ||
                              fields.exemption === null
                            "
                          ></v-select>
                        </div>
                        <div
                          v-if="errors && errors.exempt_reason_id"
                          class="text-danger text-error"
                        >
                          {{ errors.exempt_reason_id[0] }}
                        </div>
                        <div
                          class="red--text text--darken-2 mt-3 text-caption font-weight-bold"
                        >
                          弁理士法施行規則第２１条の４第１項の該当する号番号を選択してください。
                        </div>
                        <div class="text-caption">
                          <span
                            v-for="exempt_reason_id in exempt_reason_ids"
                            :key="exempt_reason_id.id"
                          >
                            {{ exempt_reason_id.id }}：{{
                              exempt_reason_id.label
                            }}<br />
                          </span>
                        </div>
                        <div
                          class="red--text text--darken-2 mt-3 text-caption font-weight-bold"
                        >
                          詳しくは、実務修習のお知らせ（パンフレット）をご確認ください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>免除申請理由</th>
                      <td>
                        <v-text-field
                          single-line
                          outlined
                          hide-details
                          dense
                          placeholder="特許事務所における特許の出願の補助業務の経験"
                          background-color="white"
                          color="cyan darken-2"
                          class="mr-2"
                          v-model="fields.exempt_reason"
                          :disabled="
                            fields.exemption === 0 || fields.exemption === null
                          "
                          autocomplete="nope"
                        ></v-text-field>
                        <div
                          v-if="errors && errors.exempt_reason"
                          class="text-danger text-error"
                        >
                          {{ errors.exempt_reason[0] }}
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          免除申請理由（号）が第１号又は第２号に該当する場合は、「特許事務所における商標の出願の補助業務の経験」等の具体的内容を入力してください。
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        従事年数<br /><span
                          class="caption indigo--text text--lighten-2 font-weight-bold"
                          >半角数字</span
                        >
                      </th>
                      <td>
                        <div style="width: 200px">
                          <v-text-field
                            single-line
                            outlined
                            hide-details
                            dense
                            placeholder="例：7"
                            background-color="white"
                            color="cyan darken-2"
                            class="mr-2"
                            v-model="fields.service_years"
                            :disabled="
                              fields.exemption === 0 ||
                              fields.exemption === null
                            "
                            autocomplete="nope"
                          ></v-text-field>
                        </div>
                        <div
                          v-if="errors && errors.service_years"
                          class="text-danger text-error"
                        >
                          {{ errors.service_years[0] }}
                        </div>
                        <div
                          class="grey--text text--darken-1 mt-3 text-caption"
                        >
                          免除申請理由（号）が第１号又は第２号に該当する場合は、工業所有権作成事務又は補助に専ら従事した年数を入力してください。
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <v-card>
              <v-card-title class="text-subtitle-1 font-weight-bold"
                >【注意事項】</v-card-title
              >
              <v-card-text class="caption black--text">
                受講申請書・一部免除申請書は必ず印刷の上、日本弁理士会宛てに申請期限内に配達証明郵便で送付してください。<br />
                <span class="font-weight-bold red--text text--darken-2"
                  >※本フォームに入力しただけでは受講申請は完了しません。</span
                >
              </v-card-text>
            </v-card>

            <div class="text-center py-5">
              <v-btn
                class="ma-2 white--text"
                x-large
                color="green darken-3 font-weight-bold"
                @click="saveTempData"
                :disabled="saveTempButtonStatus"
                :loading="saveTempButtonStatus"
              >
                下書き保存
              </v-btn>
              <v-dialog v-model="save_temp_dialog" width="400">
                <v-card>
                  <v-card-text class="pt-3">
                    <p>
                      下記IDとパスワードを保管し、上部の「下書きを復元する」ボタンから復元することができます。<br />
                      有効期限は{{ tempDataSavedPeriod }}までです。
                    </p>
                    <div class="d-flex justify-start align-center my-5">
                      <div style="width: 100px" class="font-weight-bold">
                        ID
                      </div>
                      <div class="text-h6">{{ training_temp_data_id }}</div>
                    </div>
                    <div class="d-flex justify-start align-center">
                      <div style="width: 100px" class="font-weight-bold">
                        パスワード
                      </div>
                      <div class="text-h6">
                        {{ training_temp_data_password }}
                      </div>
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions class="d-flex justify-center py-5">
                    <v-btn
                      color="grey darken-3"
                      class="white--text"
                      @click="save_temp_dialog = false"
                    >
                      確認しました
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn
                class="ma-2"
                x-large
                color="cyan darken-1 font-weight-bold white--text"
                :disabled="downloadButtonStatus"
                :loading="downloadButtonStatus"
                @click="saveAndDownload"
              >
                申請書ダウンロード
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment";
import YubinBango from "yubinbango-core2";
export default {
  data: () => ({
    isTrainingPeriod: null,
    activePicker: null,
    menu: false,
    birthDateMenu: false,
    payDateMenu: false,
    restore_temp_dialog: false,
    save_temp_dialog: false,
    sex_cds: [],
    exempt_courses: [],
    exempt_reason_ids: [],
    office_prefecture: [],
    courses: [],
    qualifications: [],
    fields: {
      destination: null,
      exemption: null,
      adress_prefecture: null,
      adress_municipality: null,
      office_prefecture: null,
      office_municipality: null,
      birth_era: '',
      birth_month: '',
      birth_day: '',
      eligibility_era: '',
    },
    errors: {},
    downloadButtonStatus: false,
    saveTempButtonStatus: false,
    restoreButtonStatus: false,
    training_temp_data_id: null,
    training_temp_data_password: null,
    temp_id: null, // 下書き復元用ID
    password: null, // 下書き復元用パスワード
    showPassword: false,
    tempDataSaveDays: null, //Master値
    tempDataSavedPeriod: moment().format("YYYY年MM月DD日"),
    birth_year_era: [],
    eligibility_year_era: [],
    months: [],
    days: [],
  }),

  mounted() {
    this.$store.commit("setOverlay", {
      overlay: true,
    });

    (async () => {
      await this.$axios.get("/api/is_training_period").then((response) => {
        this.isTrainingPeriod = response.data;
      });

      await this.$axios.get("/api/masters/sex").then((response) => {
        this.sex_cds = response.data;
      });

      await this.$axios.get("/api/masters/course").then((response) => {
        this.courses = response.data;
      });
      await this.$axios.get("/api/masters/exempt_course").then((response) => {
        this.exempt_courses = response.data;
      });
      await this.$axios
        .get("/api/masters/exempt_reason_id")
        .then((response) => {
          this.exempt_reason_ids = response.data;
        });
      await this.$axios.get("/api/masters/qualification").then((response) => {
        for (const item of response.data) {
          if (item.id != 4 && item.id != 9) {
            this.qualifications.push(item);
          }
        }
      });

      await this.$axios
        .get("/api/masters/office_prefecture")
        .then((response) => {
          this.office_prefecture = response.data;
        });

      await this.$axios
        .get("/api/master/constant/training_tempData_period")
        .then((response) => {
          this.tempDataSaveDays = response.data;
        });

      await this.$axios.get("/api/masters/japanese_year_era").then((response) => {
        this.eligibility_year_era = response.data;
        for (const item of response.data) {
          if (item.id != 'R') {
            this.birth_year_era.push(item);
          }
        }
      });

      for(var month=1; month<=12; month++){
        this.months.push({id: month, label: month});
      }

      for(var day=1; day<=31; day++){
        this.days.push({id: day, label: day});
      }

      this.$store.commit("setOverlay", {
        overlay: false,
      });
    })();
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    setYuuKouKiGen(date) {
      let tempDate = date.add(this.tempDataSaveDays, "days");
      this.tempDataSavedPeriod = tempDate.format("YYYY年MM月DD日");
    },
    changeExemption() {
      //免除申請がない場合、免除関係の項目をnullにする
      if (this.fields.exemption === 0) {
        this.$set(this.fields, 'exempt_course', null);
        this.$set(this.fields, 'exempt_reason_id', null);
        this.$set(this.fields, 'exempt_reason', null);
        this.$set(this.fields, 'service_years', null);
      }
    },

    changeDestination(){
      if (this.fields.destination === 0) {
        this.$set(this.fields, 'office_name1', null);
        this.$set(this.fields, 'office_name2', null);
        this.$set(this.fields, 'office_postalcode', null);
        this.$set(this.fields, 'office_prefecture', null);
        this.$set(this.fields, 'office_municipality', null);
        this.$set(this.fields, 'office_building', null);
      }
    },
    getPrefecture() {
      let _this = this.fields;
      _this.adress_building = "";
      new YubinBango.Core(_this.adress_postalcode, function (addr) {
        _this.adress_prefecture = addr.region;
        _this.adress_municipality = addr.locality + addr.street;
      });
    },
    getOfficePrefecture() {
      let _this = this.fields;
      _this.office_prefecture = "";
      _this.office_municipality = "";
      _this.office_building = "";

      if (_this.office_postalcode.substr(0, 3) == "000") {
        _this.office_prefecture = "海外";
      } else {
        new YubinBango.Core(_this.office_postalcode, function (addr) {
          _this.office_prefecture = addr.region;
          _this.office_municipality = addr.locality + addr.street;
        });
      }
    },
    setKaiGai(jiTaKu = false) {
      let _this = this.fields;
      if (jiTaKu) {
        _this.adress_prefecture = "";
        _this.adress_postalcode = "0000000";
        _this.adress_municipality = "";
      } else {
        _this.office_prefecture = "";
        _this.office_postalcode = "0000000";
        _this.office_municipality = "";
      }
    },
    /**
     * 申請書の保存とダウロード
     */
    saveAndDownload() {
      if(this.fields.birth_year == "元"){ //”元”と1はイコール
        this.fields.birth_year = 1;
      }
      if(this.fields.eligibility_year == "元"){ //”元”と1はイコール
        this.fields.eligibility_year = 1;
      }
      this.downloadButtonStatus = true;
      this.errors = {};
      this.$axios
        .post("/api/training_members", this.fields)
        .then((response) => {
          if (response.data.result == true) {
            let addedTrainingMember = response.data.training_member;
            this.exportPdf(
              addedTrainingMember.uuid,
              addedTrainingMember.application_number,
              response.data.message
            );
          }
        })
        .catch((error) => {
          // バリデーションエラー
          if (error.response.status === 422 || error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
              text: "入力内容にエラーがあります。内容を確認してください。",
              color: "warning",
            });
          }
          this.downloadButtonStatus = false;
        });
    },
    exportPdf(uuid, applicationNumber, message) {
      this.errors = {};
      this.$axios
        .get(`/api/doc/training_member/${uuid}/${applicationNumber}`, {
          responseType: "blob",
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/*" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "実務修習受講申請書.pdf";
          link.click();
          this.downloadButtonStatus = false;
          this.$store.commit("setMessege", {
            text: "ダウンロードされたPDFファイルをご確認ください",
            color: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            const blob = new Blob([error.response.data]);
            blob.text().then((value) => {
              const response = JSON.parse(value);
              this.errors = response.errors;
            });
          }
          this.downloadButtonStatus = false;
        });
    },
    /**
     * 下書きの保存
     */
    saveTempData() {
      this.saveTempButtonStatus = true;

      this.errors = {};
      let params = {};
      if(this.fields.birth_year == "元"){ //”元”と1はイコール
        this.fields.birth_year = 1;
      }
      if(this.fields.eligibility_year == "元"){ //”元”と1はイコール
        this.fields.eligibility_year = 1;
      }
      params = this.fields;
      // 更新
      if (this.training_temp_data_id != null) {
        params.temp_id = this.training_temp_data_id;
        params.password = this.training_temp_data_password;
      }

      this.$axios
        .post("/api/temp/training_members", params)
        .then((response) => {
          if (response.data.result == true) {
            this.training_temp_data_id = response.data.temp_id;
            this.training_temp_data_password = response.data.password;
            this.setYuuKouKiGen(moment(response.data.created_at));
            this.saveTempButtonStatus = false;
            this.save_temp_dialog = true;
          }
        })
        .catch((error) => {
          // バリデーションエラー
          if (error.response.status === 422 || error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
              text: "入力内容にエラーがあります。内容を確認してください。",
              color: "warning",
            });
          }
          this.saveTempButtonStatus = false;
        });
    },
    /**
     * 下書きの復元
     */
    restoreTempData() {
      this.errors = {};
      this.restoreButtonStatus = true;
      let params = {
        temp_id: this.temp_id,
        password: this.password,
      };
      this.$axios
        .post("/api/restore/training_members", params)
        .then((response) => {
          let trainingTempData = response.data.training_temp_data;
          this.fields = trainingTempData.data;
          this.training_temp_data_id = trainingTempData.temp_id;
          this.training_temp_data_password = trainingTempData.password;
          this.setYuuKouKiGen(moment(trainingTempData.created_at));
          this.changeExemption();
          this.changeDestination();
          this.temp_id = null;
          this.password = null;
          this.restoreButtonStatus = false;
          this.restore_temp_dialog = false;
        })
        .catch((error) => {
          if (error.response.status == "404") {
            this.$router.replace({
              path: "/404",
            });
          } else if (error.response.status === 422) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
              text: "入力内容にエラーがあります。内容を確認してください。",
              color: "warning",
            });
          } else if (error.response.status === 400) {
            this.$store.commit("setMessege", {
              text: error.response.data.message,
              color: "warning",
            });
          }

          this.restoreButtonStatus = false;
        });
    },
    parseDate(dateName) {
      let date = this.fields[dateName];
      if (!date) return null;
      let parsedDate = null;
      let delimiter = null;
      if (date.indexOf("-") !== -1) {
        delimiter = "-";
      } else if (date.indexOf("/") !== -1) {
        delimiter = "/";
      }
      if (delimiter == null) return date;
      const [year, month, day] = date.split(delimiter);
      parsedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      this.$set(this.fields, dateName, parsedDate);
    },
  },
  watch: {
    birthDateMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    message() {
      return this.$store.state.message;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table.disabled tbody {
  tr {
    opacity: 0.6 !important;
    &:hover {
      background-color: transparent !important;
    }
    th,
    td {
      background-color: #eee !important;
    }
  }
}
.text-error{
    background-color: #d90404 !important;
    color: #fff !important;
    padding:1px;
    margin:1px;
}
</style>
