<template>
  <v-container grid-list-md>
    <h2 class="pt-4 pb-2 text-center">会員登録情報 変更申請</h2>

    

    <div v-if="errors && errors.nochange" class="text-danger text_align">
      {{ errors.nochange }}
    </div>

    <v-row class="my-5" justify="center" align="center">

      <v-btn
        class="ma-2"
        dark
        color="success font-weight-bold"
        href="/doc/登録情報の確認・変更に関するマニュアル.pdf"
        target="_blank"
      >
        <v-icon left>
        mdi-comment-question-outline
        </v-icon>
        登録情報の確認・変更に関するマニュアル
      </v-btn>

    </v-row>
    

    <v-row class="my-5" justify="center" align="center">
      <v-col lg="8">
        <PersonTable
          :fields="fields"
          :errors="errors"
          :changes="personChangePresence"
          :officeChanges="officeChangePresence"
          v-if="fields.registration_number"
        />
        <OfficeTable
          :fields="fields"
          :errors="errors"
          :changes="officeChangePresence"
          :personChanges="personChangePresence"
          v-if="fields.registration_number"
        />
        <div class="text-center py-5">
          <v-btn
            class="ma-2"
            dark
            x-large
            color="cyan darken-1 font-weight-bold"
            :loading="buttonStatus"
            @click="submit"
          >
            申請書を作成する
          </v-btn>
          <v-btn
            class="ma-2"
            x-large
            color=" font-weight-bold"
            @click="cancel"
          >
            申請書を作成せず終了する
          </v-btn>
        </div>

        <v-alert class="my-4" type="error">申請書には氏名の自署、または氏名欄横に押印が必要です</v-alert>
        <v-alert
          border="left"
          colored-border
          color="success accent-4"
          elevation="2"
        >
          <p>
            「申請書を作成する」をクリックすると、「弁理士登録・届出事項変更届」のPDFファイルがダウンロードされます。<br>こちらを印刷のうえ、<span class="red--text text-h6">氏名を自署、または氏名欄横に押印した原本</span>を以下までお送りください。<br /><br />
            【提出先】<br />
            〒100-0013 東京都千代田区霞が関 3-2-6 東京倶楽部ビル14階<br />
            日本弁理士会 会員課
          </p>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PersonTable from "./PersonTable";
import OfficeTable from "./OfficeTable";

export default {
  components: { PersonTable, OfficeTable },
  data() {
    return {
      fields: {},
      errors: {},
      personChangePresence: {
        name_change_presence: false,
        oldname_change_presence: false,
        register_name_writing_change_presence: false,
        adress_change_presence: false,
        checkboxTel: false,
        public_servant_change_presence: false,
        address_range_change_presence: false,
      },
      officeChangePresence: {
        office_state_change_presence: [],
        name_change_presence: [],
        adress_change_presence: [],
        tel1_change_presence: [],
        tel2_change_presence: [],
        tel1_extension_change_presence: [],
        tel2_extension_change_presence: [],
        fax1_change_presence: [],
        fax2_change_presence: [],
        office_oparation_change_presence: [],
        retirement_date_change_presence:[]
      },
      hasSuccessor: {
        id: null,
        name_kanji: null,
        exist: null,
      },
      buttonStatus: false,
    };
  },
  mounted() {
    this.$store.commit("setOverlay", {
      overlay: true,
    });
    (async () => {
      this.$axios.get(`/api/persons_change`).then((response) => {
        this.fields = response.data;
        let arrData = response.data;
        let data5 = [];
        let tempArr = JSON.parse(JSON.stringify(arrData.belong_offices));
        let len = Object.keys(tempArr).length;
        for (var i = 1; i < len + 1; ++i) {
          data5.push(tempArr[i]);
        }
        (this.fields.change_requested_at = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)),
          this.getSuccessor();
        this.$store.commit("setOverlay", {
          overlay: false,
        });
      });
    })();
  },
  methods: {
    getSuccessor() {
      var value = this.fields.remaining_work_transfer_number;
      if (value) {
        this.$axios
          .get(`/api/member_by_number/${value}`)
          .then((response) => {
            if (response.data.exist == true && response.data.type == "person") {
              this.hasSuccessor = response.data;
            } else if (response.data.type == "corporation") {
              this.hasSuccessor.type = "";
              this.hasSuccessor.id = "";
              this.hasSuccessor.exist = "";
              this.hasSuccessor.name_kanji = "";
            }
          })
          .catch((error) => {
            if (error.response.status == "404") {
              this.$router.replace({
                path: "/404",
              });
            }
          });
      }
    },
    cancel() {
      if (!window.confirm("申請書を作成せず終了すると、入力内容は保存されず、変更の申請もされません。終了してよろしいですか。")) {
        return;
      }
      this.$router.push({ path: `/` });
    },
    submit() {
      if (!window.confirm("弁理士登録・届出事項変更届のPDFファイルをダウンロードします。変更の届出には、自署もしくは押印のある原本のご提出が必要となります。よろしいですか。")) {
        return;
      }
      this.buttonStatus = true;
      this.fields.personChange = this.personChangePresence;
      this.fields.officeChange = this.officeChangePresence;
      if (this.fields.is_oldname != 1) {
        this.personChangePresence.register_name_writing_change_presence = false;
      }

      let mainOfficeCount = 0 //主たる事務所1件必要バリデーション

      //全角英数字 => 半角処理 start
      for (let [fieldName, fieldValue] of Object.entries(this.fields)) {
        this.fields[fieldName] = this.zenKakuToHanKaKu(fieldValue,fieldName);
      }

      for (let [index, belong_office] of Object.entries(
        this.fields.belong_offices
      )) {
        for (let [fieldName, fieldValue] of Object.entries(belong_office)) {
          belong_office[fieldName] = this.zenKakuToHanKaKu(fieldValue,fieldName);
        }
        
        if(belong_office['office_state_cd'] == 1) {
          mainOfficeCount++;
        }
      }
      //全角英数字 => 半角処理 end

      if(mainOfficeCount < 1) {
        this.buttonStatus = false;
        this.$store.commit('setMessege', {
          text: '主たる事務所は1件必要です。',
          color: 'warning',
        })
        return false;
      }

      this.$axios
        .post(`/api/persons_change`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            let message = response.data.message;
            let person_change_request_id =
              response.data.person_change_request_id;
            this.exportPdf(person_change_request_id, message);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
              text: "入力内容にエラーがあります。内容を確認してください。",
              color: "warning",
            });
            this.buttonStatus = false;
          } else {
            this.buttonStatus = false;
          }
        });
    },
    exportPdf(id, message) {
      let params = [];
      for (let [fieldName, fieldValue] of Object.entries(this.fields.belong_offices)) {
          if(fieldValue.retirement_date != null){
             params.push({
              'office_cd' : (fieldValue['cd'] != null)?fieldValue['cd']:null,
              'retirement_date' : fieldValue['retirement_date'],
              'name' : fieldValue['offfice_kanji'],
              'office_state_cd': fieldValue['office_state_cd'],
            });
          }
      } 
      this.errors = {};
      this.$axios
        .post(`/api/doc/notification_change_form/${id}`, {params}, {
          responseType: "blob",
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/*" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "弁理士登録・届出事項変更届.pdf";
          link.click();
          this.buttonStatus = false;
          this.$store.commit("setMessege", {
            text: message,
            color: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            const blob = new Blob([error.response.data]);
            blob.text().then((value) => {
              const response = JSON.parse(value);
              this.errors = response.errors;
              this.buttonStatus = false;
            });
          } else {
            this.buttonStatus = false;
          }
        });
    },
  },
};
</script>

<style type="scss">
.datepick input[type="text"] {
  width: 100px;
}
.active {
  background-color: #e0f7fa !important;
}
td.top {
  vertical-align: top !important;
}
.text_align {
  margin-left: 200px !important;
}
</style>
